import '../styles/index.scss';

if (process.env.NODE_ENV === 'development') {
  require('../index.html');
}


window.onload = function() {

  window.scrollTo(0, 0);
  /* Block temporarily the scroll to let the images start loading */
  setTimeout(() => {
    // One of this must stay, this is just for a test
    const cnt = document.getElementById('content-wrapper');
    cnt.style.overflowY = 'scroll';
  }, 4000);

  const html = document.documentElement;
  const canvas = document.getElementById('showcase-wrapper');
  const contentWrapper = document.getElementById('content-wrapper');
  const context = canvas.getContext('2d');
  let frameIndex = 1;


  /* Create a batch of images to keep loading and be prepared to be
  drawn in the canvas element */
  let imagesBatch = [];
  let imagesIndex = 1;

  const currentFrame = index => (
    `/public/img/frames/${index.toString().padStart(3,'0')}.jpg`
  );

  const setCurrentFrame = index => imagesBatch[index];

  const loadBatchImages = offset => {
    const currentIndex = imagesIndex + 70;

      for(let i = imagesIndex; i <= currentIndex; i++) {
        if (imagesIndex < 452) {
          imagesBatch[i] = new Image();
          imagesBatch[i].src = `/public/img/frames/${i.toString().padStart(3,'0')}.jpg`;
          imagesIndex++;
        }
      }

  };

  // Load first image
  const image = new Image();
  image.src = currentFrame(1);

  // Load first batch of images
  loadBatchImages(imagesIndex);

  image.onload = function() {
    context.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);
  };

  $(".showcase-wrapper").niceScroll();
  // Alternative to last method to let the content not be visible on all body
  // this should listen to the scroll of content Wrapper and behave according to that
  contentWrapper.addEventListener('scroll', (e) => {
    loadBatchImages(imagesIndex);
    const scrollTop = contentWrapper.scrollTop;
    const maxScroll = contentWrapper.scrollHeight - contentWrapper.offsetHeight;
    const scrollFraction = scrollTop / maxScroll;
    frameIndex = Math.min(451, Math.floor(scrollFraction * 451));
    requestAnimationFrame(() => updateCanvas(frameIndex));

  });

  // Draw an image on the canvas based on the index given
  const updateCanvas = index => {
    image.src = setCurrentFrame(index).src;
    context.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);
  };

  // Too keep the aspect ratio of the images, calc and set the height of the canvas
  const setCanvas = () => {
    const ratio = 1920 / 1080; // based on the images dimension
    canvas.width  = window.innerWidth;
    canvas.height = window.innerWidth/ratio;

    contentWrapper.style.height = `${window.innerWidth/ratio}px`;
    requestAnimationFrame(() => updateCanvas(frameIndex));
  };

  setCanvas();

  window.addEventListener('resize', () => setCanvas());

  /* From here, it is about the story telling using ScrllMagic */
  // init controller
  const controller = new ScrollMagic.Controller();

  // Define scenes
  const scenes = [
    {
      text: 'LOS VOLCANES',
      class: '-center -title'
    },
    {
      text: 'En el valle de Puebla, los <br> volcanes son testigos de <br> este maravilloso viaje',
      class: '-right'
    },
    {
      text: 'LA LLUVIA',
      class: '-center -title'
    },
    {
      text: 'Cada gota de lluvia que cae en <br> este valle, da inicio al viaje <br> de 12 años de Agua Sta.María<small class="copy">&reg;</small>',
      class: '-left'
    },
    {
      text: 'EL BOSQUE',
      class: '-center -title'
    },
    {
      text: 'El bosque de Santa Rita Tlahuapan,<br> que rodea al manantial',
      class: '-left'
    },
    {
      text: 'es el encargado de <br> capturar y retener el agua de lluvia para <br> filtrarla a los mantos acuíferos',
      class: '-right'
    },
    {
      text: 'EL SUBSUELO',
      class: '-center -title'
    },
    {
      text: 'Aquí, cada gota comienza a <br> filtrarse por el subsuelo',
      class :'-right'
    },
    {
      text: 'El agua continua su viaje en las rocas <br> volcánicas donde adquiere <br> minerales únicos ',
      class: '-center'
    },
    {
      text: 'Este viaje de 12 años de filtración natural <br> le da a Agua Sta.María<small class="copy">&reg;</small> un sabor único. <br/> <img src="/public/img/sello-sta.svg" alt="Agua Sta. María" style="max-width: 300px; margin-left: 100px;"/>',
      class: '-left'
    },
    {
      text: 'EL MANANTIAL',
      class: '-center -title'
    },
    {
      text: 'El agua brota a la superficie <br>  en forma de manantial donde <br> es envasada directo de la fuente.',
      class: '-right'
    },
    {
      text: 'Así logramos que llegue a ti, tal <br> y como la naturaleza <br> la creó.',
      class: '-center'
    },
    {
      text: '<img src="/public/img/logo-main.png" alt="Agua Sta. María" style="max-width: 470px; margin-bottom: 10px" /> <br> <strong>De la naturaleza, para ti</strong> <br> <img src="/public/img/sello-sta.svg" alt="Agua Sta. María" style="max-width: 200px;"/>',
      class: '-center'
    },
  ];

  const wrapper = document.getElementById('content-wrapper');

  // create nodes and scenes
  scenes.forEach( (element, i) => {
    const node = document.createElement('section');
    node.className = "frame-scene";

    let newElement

    if (element.text) {
      newElement = `
          <div class="spacer"></div>
          <div id="trigger${i+1}"></div>
          <div id="reveal${i+1}" class="reveal box ${element.class}">
            <p class="scene-text">${element.text}</p>
          </div>
          <div class="spacer"></div>
      `;
    } else {
      newElement = `<div class="spacer"></div>`
    }


    node.innerHTML = newElement;

    wrapper.appendChild(node);

    new ScrollMagic.Scene({
      triggerElement: `#trigger${i+1}`,
      triggerHook: 0.9, // show, when scrolled 10% into view
      duration: "80%", // hide 10% before exiting view (80% + 10% from bottom)
      offset: 10 // move trigger to center of element
    })
    .setClassToggle(`#reveal${i+1}`, "visible") // add class to reveal
    .addTo(controller);
  });
};

